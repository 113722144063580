var xmldoc = require('xmldoc');

function createShape(svg) {
    var dom = new xmldoc.XmlDocument(svg);
    var width, height;

    if (dom.attr.width && dom.attr.height) {
        width = parseInt(dom.attr.width, 10);
        height = parseInt(dom.attr.height, 10);
    } else if (dom.attr.viewBox) {
        width = Math.ceil(dom.attr.viewBox.split(" ")[2]);
        height = Math.ceil(dom.attr.viewBox.split(" ")[3]);
    } else {
        console.log(dom);
        throw new Error("Cannot handle this dom element");
    }

    var shape = new xmldoc.XmlDocument('<svg><g /></svg>');

    var group = shape.firstChild;
    group.children = dom.children;
    group.attr.transform = "translate(-" + width / 2 + ", -" + height / 2 + ")";

    return shape;
}

function createElement(shapeSvg, position, title, id) {
    var group = (new xmldoc.XmlDocument('<svg><g /></svg>')).firstChild;
    group.children = createShape(shapeSvg).children;

    // translate to posX and posY, rotate by rotZ
    group.attr.transform = "translate(" + position[0] + ", " + position[1] + ")";
    if (position[2]) {
        group.attr.transform += " rotate(" + position[2] + ")";
    }

    // set title
    if (title) {
        group.attr.title = title;
    }

    // set id
    if (id) {
        group.attr.id = id;
    }

    return group;
}

module.exports = function (width, height) {
    // initial values
    var svg = [
        '<?xml version="1.0" encoding="UTF-8"?>',
        '<!-- Generated by StadLine: resamania-biking -->',
        '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 ' + width + ' ' + height + '">',
        '</svg>'
    ].join('\n');

    var dom = new xmldoc.XmlDocument(svg);

    // methods
    this.addShape = function (shape, position, title, id) {
        dom.children.push(createElement(shape, position, title, id));

        return this;
    };

    this.value = function () {
        if (dom.children.length > 0) {
            dom.firstChild = dom.children[0];
            dom.lastChild = dom.children[dom.length - 1];
        }

        // return result
        return dom;
    };
};