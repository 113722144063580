var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"col-md-4\">\n    <a data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 1, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin:resourceId",{"resourceId": (lineno = 1, colno = 88, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"getId", env.opts.autoescape), "session[\"getId\"]", []))}])), env.opts.autoescape);
output += "\">\n        <div class=\"session-tile\">\n            <h4>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape), env.opts.autoescape);
output += "</h4>\n            <div class=\"tile-action\">\n                <div><i class=\"fa fa-calendar\"></i> ";
output += runtime.suppressValue(env.getFilter("toDateString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</div>\n                <div><i class=\"fa fa-clock-o\"></i> ";
output += runtime.suppressValue(env.getFilter("toTimeString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</div>\n            </div>\n        </div>\n    </a>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
