var Marionette = require("backbone.marionette");
var tpl = require("../templates/map.nunj");
var vectorMap = require('../lib/vectormap.js');
var _ = require('underscore');
var $ = require('jquery');
var app = require('ampersand-app');

// shapes
var bike = require('resources/svg/bike');
var step = require('resources/svg/step');
var punchingBag = require('resources/svg/punching-bag');
var yogaMat = require('resources/svg/yoga-mat');
var treadmill = require('resources/svg/treadmill');
var triangle = require('resources/svg/triangle');
var rectangle = require('resources/svg/rectangle');
var circle = require('resources/svg/circle');
var items = {
  'coach bike': bike,
  'bike': bike,
  'step': step,
  'coach step': step,
  'punching bag': punchingBag,
  'coach punching bag': punchingBag,
  'yoga mat': yogaMat,
  'coach yoga mat': yogaMat,
  'treadmill': treadmill,
  'coach treadmill': treadmill,
  'triangle': triangle,
  'coach triangle': triangle,
  'rectangle': rectangle,
  'coach rectangle': rectangle,
  'circle': circle,
  'coach circle': circle
};
var coach = require('resources/svg/coach');
var numbers = {
  '1': require('resources/svg/nb01'),
  '1-o': require('resources/svg/nb01-o'),
  '1-circle': require('resources/svg/nb01-circle'),
  '2': require('resources/svg/nb02'),
  '2-o': require('resources/svg/nb02-o'),
  '2-circle': require('resources/svg/nb02-circle'),
  '3': require('resources/svg/nb03'),
  '3-o': require('resources/svg/nb03-o'),
  '3-circle': require('resources/svg/nb03-circle'),
  '4': require('resources/svg/nb04'),
  '4-o': require('resources/svg/nb04-o'),
  '4-circle': require('resources/svg/nb04-circle'),
  '5': require('resources/svg/nb05'),
  '5-o': require('resources/svg/nb05-o'),
  '5-circle': require('resources/svg/nb05-circle'),
  '6': require('resources/svg/nb06'),
  '6-o': require('resources/svg/nb06-o'),
  '6-circle': require('resources/svg/nb06-circle'),
  '7': require('resources/svg/nb07'),
  '7-o': require('resources/svg/nb07-o'),
  '7-circle': require('resources/svg/nb07-circle'),
  '8': require('resources/svg/nb08'),
  '8-o': require('resources/svg/nb08-o'),
  '8-circle': require('resources/svg/nb08-circle'),
  '9': require('resources/svg/nb09'),
  '9-o': require('resources/svg/nb09-o'),
  '9-circle': require('resources/svg/nb09-circle'),
  '10': require('resources/svg/nb10'),
  '10-o': require('resources/svg/nb10-o'),
  '10-circle': require('resources/svg/nb10-circle'),
  '11': require('resources/svg/nb11'),
  '11-o': require('resources/svg/nb11-o'),
  '11-circle': require('resources/svg/nb11-circle'),
  '12': require('resources/svg/nb12'),
  '12-o': require('resources/svg/nb12-o'),
  '12-circle': require('resources/svg/nb12-circle'),
  '13': require('resources/svg/nb13'),
  '13-o': require('resources/svg/nb13-o'),
  '13-circle': require('resources/svg/nb13-circle'),
  '14': require('resources/svg/nb14'),
  '14-o': require('resources/svg/nb14-o'),
  '14-circle': require('resources/svg/nb14-circle'),
  '15': require('resources/svg/nb15'),
  '15-o': require('resources/svg/nb15-o'),
  '15-circle': require('resources/svg/nb15-circle'),
  '16': require('resources/svg/nb16'),
  '16-o': require('resources/svg/nb16-o'),
  '16-circle': require('resources/svg/nb16-circle'),
  '17': require('resources/svg/nb17'),
  '17-o': require('resources/svg/nb17-o'),
  '17-circle': require('resources/svg/nb17-circle'),
  '18': require('resources/svg/nb18'),
  '18-o': require('resources/svg/nb18-o'),
  '18-circle': require('resources/svg/nb18-circle'),
  '19': require('resources/svg/nb19'),
  '19-o': require('resources/svg/nb19-o'),
  '19-circle': require('resources/svg/nb19-circle'),
  '20': require('resources/svg/nb20'),
  '20-o': require('resources/svg/nb20-o'),
  '20-circle': require('resources/svg/nb20-circle'),
  '21': require('resources/svg/nb21'),
  '21-o': require('resources/svg/nb21-o'),
  '21-circle': require('resources/svg/nb21-circle'),
  '22': require('resources/svg/nb22'),
  '22-o': require('resources/svg/nb22-o'),
  '22-circle': require('resources/svg/nb22-circle'),
  '23': require('resources/svg/nb23'),
  '23-o': require('resources/svg/nb23-o'),
  '23-circle': require('resources/svg/nb23-circle'),
  '24': require('resources/svg/nb24'),
  '24-o': require('resources/svg/nb24-o'),
  '24-circle': require('resources/svg/nb24-circle'),
  '25': require('resources/svg/nb25'),
  '25-o': require('resources/svg/nb25-o'),
  '25-circle': require('resources/svg/nb25-circle'),
  '26': require('resources/svg/nb26'),
  '26-o': require('resources/svg/nb26-o'),
  '26-circle': require('resources/svg/nb26-circle'),
  '27': require('resources/svg/nb27'),
  '27-o': require('resources/svg/nb27-o'),
  '27-circle': require('resources/svg/nb27-circle'),
  '28': require('resources/svg/nb28'),
  '28-o': require('resources/svg/nb28-o'),
  '28-circle': require('resources/svg/nb28-circle'),
  '29': require('resources/svg/nb29'),
  '29-o': require('resources/svg/nb29-o'),
  '29-circle': require('resources/svg/nb29-circle'),
  '30': require('resources/svg/nb30'),
  '30-o': require('resources/svg/nb30-o'),
  '30-circle': require('resources/svg/nb30-circle'),
  '31': require('resources/svg/nb31'),
  '31-o': require('resources/svg/nb31-o'),
  '31-circle': require('resources/svg/nb31-circle'),
  '32': require('resources/svg/nb32'),
  '32-o': require('resources/svg/nb32-o'),
  '32-circle': require('resources/svg/nb32-circle'),
  '33': require('resources/svg/nb33'),
  '33-o': require('resources/svg/nb33-o'),
  '33-circle': require('resources/svg/nb33-circle'),
  '34': require('resources/svg/nb34'),
  '34-o': require('resources/svg/nb34-o'),
  '34-circle': require('resources/svg/nb34-circle'),
  '35': require('resources/svg/nb35'),
  '35-o': require('resources/svg/nb35-o'),
  '35-circle': require('resources/svg/nb35-circle'),
  '36': require('resources/svg/nb36'),
  '36-o': require('resources/svg/nb36-o'),
  '36-circle': require('resources/svg/nb36-circle'),
  '37': require('resources/svg/nb37'),
  '37-o': require('resources/svg/nb37-o'),
  '37-circle': require('resources/svg/nb37-circle'),
  '38': require('resources/svg/nb38'),
  '38-o': require('resources/svg/nb38-o'),
  '38-circle': require('resources/svg/nb38-circle'),
  '39': require('resources/svg/nb39'),
  '39-o': require('resources/svg/nb39-o'),
  '39-circle': require('resources/svg/nb39-circle'),
  '40': require('resources/svg/nb40'),
  '40-o': require('resources/svg/nb40-o'),
  '40-circle': require('resources/svg/nb40-circle'),
  '41': require('resources/svg/nb41'),
  '41-o': require('resources/svg/nb41-o'),
  '41-circle': require('resources/svg/nb41-circle'),
  '42': require('resources/svg/nb42'),
  '42-o': require('resources/svg/nb42-o'),
  '42-circle': require('resources/svg/nb42-circle'),
  '43': require('resources/svg/nb43'),
  '43-o': require('resources/svg/nb43-o'),
  '43-circle': require('resources/svg/nb43-circle'),
  '44': require('resources/svg/nb44'),
  '44-o': require('resources/svg/nb44-o'),
  '44-circle': require('resources/svg/nb44-circle'),
  '45': require('resources/svg/nb45'),
  '45-o': require('resources/svg/nb45-o'),
  '45-circle': require('resources/svg/nb45-circle'),
  '46': require('resources/svg/nb46'),
  '46-o': require('resources/svg/nb46-o'),
  '46-circle': require('resources/svg/nb46-circle'),
  '47': require('resources/svg/nb47'),
  '47-o': require('resources/svg/nb47-o'),
  '47-circle': require('resources/svg/nb47-circle'),
  '48': require('resources/svg/nb48'),
  '48-o': require('resources/svg/nb48-o'),
  '48-circle': require('resources/svg/nb48-circle'),
  '49': require('resources/svg/nb49'),
  '49-o': require('resources/svg/nb49-o'),
  '49-circle': require('resources/svg/nb49-circle'),
  '50': require('resources/svg/nb50'),
  '50-o': require('resources/svg/nb50-o'),
  '50-circle': require('resources/svg/nb50-circle'),
  '51': require('resources/svg/nb51'),
  '51-o': require('resources/svg/nb51-o'),
  '51-circle': require('resources/svg/nb51-circle'),
  '52': require('resources/svg/nb52'),
  '52-o': require('resources/svg/nb52-o'),
  '52-circle': require('resources/svg/nb52-circle'),
  '53': require('resources/svg/nb53'),
  '53-o': require('resources/svg/nb53-o'),
  '53-circle': require('resources/svg/nb53-circle'),
  '54': require('resources/svg/nb54'),
  '54-o': require('resources/svg/nb54-o'),
  '54-circle': require('resources/svg/nb54-circle'),
  '55': require('resources/svg/nb55'),
  '55-o': require('resources/svg/nb55-o'),
  '55-circle': require('resources/svg/nb55-circle'),
  '56': require('resources/svg/nb56'),
  '56-o': require('resources/svg/nb56-o'),
  '56-circle': require('resources/svg/nb56-circle'),
  '57': require('resources/svg/nb57'),
  '57-o': require('resources/svg/nb57-o'),
  '57-circle': require('resources/svg/nb57-circle'),
  '58': require('resources/svg/nb58'),
  '58-o': require('resources/svg/nb58-o'),
  '58-circle': require('resources/svg/nb58-circle'),
  '59': require('resources/svg/nb59'),
  '59-o': require('resources/svg/nb59-o'),
  '59-circle': require('resources/svg/nb59-circle'),
  '60': require('resources/svg/nb60'),
  '60-o': require('resources/svg/nb60-o'),
  '60-circle': require('resources/svg/nb60-circle'),
  '61': require('resources/svg/nb61'),
  '61-o': require('resources/svg/nb61-o'),
  '61-circle': require('resources/svg/nb61-circle'),
  '62': require('resources/svg/nb62'),
  '62-o': require('resources/svg/nb62-o'),
  '62-circle': require('resources/svg/nb62-circle'),
  '63': require('resources/svg/nb63'),
  '63-o': require('resources/svg/nb63-o'),
  '63-circle': require('resources/svg/nb63-circle'),
  '64': require('resources/svg/nb64'),
  '64-o': require('resources/svg/nb64-o'),
  '64-circle': require('resources/svg/nb64-circle'),
  '65': require('resources/svg/nb65'),
  '65-o': require('resources/svg/nb65-o'),
  '65-circle': require('resources/svg/nb65-circle'),
  '66': require('resources/svg/nb66'),
  '66-o': require('resources/svg/nb66-o'),
  '66-circle': require('resources/svg/nb66-circle'),
  '67': require('resources/svg/nb67'),
  '67-o': require('resources/svg/nb67-o'),
  '67-circle': require('resources/svg/nb67-circle'),
  '68': require('resources/svg/nb68'),
  '68-o': require('resources/svg/nb68-o'),
  '68-circle': require('resources/svg/nb68-circle'),
  '69': require('resources/svg/nb69'),
  '69-o': require('resources/svg/nb69-o'),
  '69-circle': require('resources/svg/nb69-circle'),
  '70': require('resources/svg/nb70'),
  '70-o': require('resources/svg/nb70-o'),
  '70-circle': require('resources/svg/nb70-circle'),
  '71': require('resources/svg/nb71'),
  '71-o': require('resources/svg/nb71-o'),
  '71-circle': require('resources/svg/nb71-circle'),
  '72': require('resources/svg/nb72'),
  '72-o': require('resources/svg/nb72-o'),
  '72-circle': require('resources/svg/nb72-circle'),
  '73': require('resources/svg/nb73'),
  '73-o': require('resources/svg/nb73-o'),
  '73-circle': require('resources/svg/nb73-circle'),
  '74': require('resources/svg/nb74'),
  '74-o': require('resources/svg/nb74-o'),
  '74-circle': require('resources/svg/nb74-circle'),
  '75': require('resources/svg/nb75'),
  '75-o': require('resources/svg/nb75-o'),
  '75-circle': require('resources/svg/nb75-circle'),
  '76': require('resources/svg/nb76'),
  '76-o': require('resources/svg/nb76-o'),
  '76-circle': require('resources/svg/nb76-circle'),
  '77': require('resources/svg/nb77'),
  '77-o': require('resources/svg/nb77-o'),
  '77-circle': require('resources/svg/nb77-circle'),
  '78': require('resources/svg/nb78'),
  '78-o': require('resources/svg/nb78-o'),
  '78-circle': require('resources/svg/nb78-circle'),
  '79': require('resources/svg/nb79'),
  '79-o': require('resources/svg/nb79-o'),
  '79-circle': require('resources/svg/nb79-circle'),
  '80': require('resources/svg/nb80'),
  '80-o': require('resources/svg/nb80-o'),
  '80-circle': require('resources/svg/nb80-circle'),
  '81': require('resources/svg/nb81'),
  '81-o': require('resources/svg/nb81-o'),
  '81-circle': require('resources/svg/nb81-circle'),
  '82': require('resources/svg/nb82'),
  '82-o': require('resources/svg/nb82-o'),
  '82-circle': require('resources/svg/nb82-circle'),
  '83': require('resources/svg/nb83'),
  '83-o': require('resources/svg/nb83-o'),
  '83-circle': require('resources/svg/nb83-circle'),
  '84': require('resources/svg/nb84'),
  '84-o': require('resources/svg/nb84-o'),
  '84-circle': require('resources/svg/nb84-circle'),
  '85': require('resources/svg/nb85'),
  '85-o': require('resources/svg/nb85-o'),
  '85-circle': require('resources/svg/nb85-circle'),
  '86': require('resources/svg/nb86'),
  '86-o': require('resources/svg/nb86-o'),
  '86-circle': require('resources/svg/nb86-circle'),
  '87': require('resources/svg/nb87'),
  '87-o': require('resources/svg/nb87-o'),
  '87-circle': require('resources/svg/nb87-circle'),
  '88': require('resources/svg/nb88'),
  '88-o': require('resources/svg/nb88-o'),
  '88-circle': require('resources/svg/nb88-circle'),
  '89': require('resources/svg/nb89'),
  '89-o': require('resources/svg/nb89-o'),
  '89-circle': require('resources/svg/nb89-circle'),
  '90': require('resources/svg/nb90'),
  '90-o': require('resources/svg/nb90-o'),
  '90-circle': require('resources/svg/nb90-circle'),
  '91': require('resources/svg/nb91'),
  '91-o': require('resources/svg/nb91-o'),
  '91-circle': require('resources/svg/nb91-circle'),
  '92': require('resources/svg/nb92'),
  '92-o': require('resources/svg/nb92-o'),
  '92-circle': require('resources/svg/nb92-circle'),
  '93': require('resources/svg/nb93'),
  '93-o': require('resources/svg/nb93-o'),
  '93-circle': require('resources/svg/nb93-circle'),
  '94': require('resources/svg/nb94'),
  '94-o': require('resources/svg/nb94-o'),
  '94-circle': require('resources/svg/nb94-circle'),
  '95': require('resources/svg/nb95'),
  '95-o': require('resources/svg/nb95-o'),
  '95-circle': require('resources/svg/nb95-circle'),
  '96': require('resources/svg/nb96'),
  '96-o': require('resources/svg/nb96-o'),
  '96-circle': require('resources/svg/nb96-circle'),
  '97': require('resources/svg/nb97'),
  '97-o': require('resources/svg/nb97-o'),
  '97-circle': require('resources/svg/nb97-circle'),
  '98': require('resources/svg/nb98'),
  '98-o': require('resources/svg/nb98-o'),
  '98-circle': require('resources/svg/nb98-circle'),
  '99': require('resources/svg/nb99'),
  '99-o': require('resources/svg/nb99-o'),
  '99-circle': require('resources/svg/nb99-circle')
};
var validationActionTextByToken = {};

module.exports = Marionette.ItemView.extend({
    className: "vmap-container",
    template: function(data) {
        return tpl.render(data);
    },
    serializeData: function () {
        return {
            'helpText': this.model.session.template.helpText
        };
    },
    modelEvents: {
        'change:selectedItemToken': function () {
            this.map.clearSelectedRegions();

            if (this.model.selectedItemToken && this.map.regions.hasOwnProperty(this.model.selectedItemToken)) {
                this.map.setSelectedRegions(this.model.selectedItemToken);
                var body = app.rootView.el;
                body.querySelector('.validation-action-text').innerHTML = validationActionTextByToken[this.model.selectedItemToken];
                body.querySelector('.bike-display-name').innerHTML = '(' + this.map.regions[this.model.selectedItemToken].config.name + ')';
            }
        },
        'change:itemToken': function () {
            this.map.clearSelectedRegions();
        }
    },
    events: {
        'click .vmap-focus': function () {
            if (this.model.selectedItemToken) {
                this.map.setFocus({
                    region: this.model.selectedItemToken,
                    animate: true
                });
            } else {
                this.map.setFocus({
                    region: this.model.itemToken,
                    animate: true
                });
            }
        },
        'click .vmap-zoomin': function () {
            this.map.setScale(
              this.map.scale * this.map.params.zoomStep,
              this.map.width / 2,
              this.map.height / 2,
              false,
              this.map.params.zoomAnimate
            );
        },
        'click .vmap-zoomout': function () {
            this.map.setScale(
              this.map.scale / this.map.params.zoomStep,
              this.map.width / 2,
              this.map.height / 2,
              false,
              this.map.params.zoomAnimate
            );
        },
      'click .remove-helptext': function () {
          this.$('.help-text').hide();
      }
    },
    initialize: function () {
        var layout = this.model.session.layout;
        var bookings = this.model.session.bookings;
        var bookingsDisplayNames = [];

        var layoutWidth = layout.width;
        var layoutHeight = layout.height;
        var padX = layoutWidth * 0.5;
        var padY = layoutHeight * 0.5;

        // add elements
        var builder = vectorMap.getBuilder(layoutWidth + padX * 2, layoutHeight + padY * 2);

        // Define if bike tooltip will contains item.displayName or item.displayName + booking.displayName

        bookings.forEach(function (booking) {
            if (booking.itemToken) {
                bookingsDisplayNames[booking.itemToken] = booking.displayName;
            }
        });

        var self = this;

        layout.items.forEach(function (item) {
            var displayName;

            if (bookingsDisplayNames[item.token] && self.model.itemToken !== item.token) {
                displayName = item.displayName + ' - ' + bookingsDisplayNames[item.token];
            } else {
                displayName = item.displayName;
            }

            var delta = self.getDelta(item.type);
            var number = parseInt(item.displayName.replace("n°",""));

            if (item.type.indexOf('coach') !== -1) {
                builder.addShape(coach, [item.posX + padX + delta * Math.round(Math.sin(item.rotZ * Math.PI / 180.0) * 100) / 100, item.posY + padY - delta * Math.round(Math.cos(item.rotZ * Math.PI / 180.0) * 100) / 100, 0]);
            } else if (numbers[number+""]) {
                builder.addShape(numbers[number+""], [item.posX + padX + delta * Math.round(Math.sin(item.rotZ * Math.PI / 180.0) * 100) / 100, item.posY + padY - delta * Math.round(Math.cos(item.rotZ * Math.PI / 180.0) * 100) / 100, 0]);
            }

            builder.addShape(items[item.type], [item.posX + padX, item.posY + padY, item.rotZ], displayName, item.token);
            validationActionTextByToken[item.token] = item.validationActionText;
        });

        builder.save('custom');

        setTimeout(function() {
          var body = app.rootView.el;
          var tokenToDisplay = self.model.selectedItemToken ? self.model.selectedItemToken : self.model.itemToken;
          body.querySelector('.validation-action-text').innerHTML = validationActionTextByToken[tokenToDisplay];
          body.querySelector('.bike-display-name').innerHTML = '(' + self.map.regions[tokenToDisplay].config.name + ')';
        }, 500);

        // add event listeners
        this.listenTo(this.model.session.bookings, 'change:itemToken', this.refresh);
    },
    getDelta: function (itemType) {
      var delta = 50;

      switch (true) {
        case itemType.indexOf('treadmill') !== -1 :
          delta = 85;
          break;
        case itemType.indexOf('yoga mat') !== -1 :
          delta = 35;
          break;
        case itemType.indexOf('step') !== -1 :
          delta = 32;
          break;
        case itemType.indexOf('punching bag') !== -1 || itemType.indexOf('angle') !== -1 || itemType.indexOf('circle') !== -1 :
          delta = 30;
          break;
        case itemType.indexOf('coach bike') !== -1 :
          delta = 55;
          break;
      }

      return delta;
    },
    onDomRefresh: function () {
        var booking = this.model;
        this.itemStates = this.getItemStates();
        this.itemNames = this.getItemNames();
        var $legend = $('.legend');

        $('div#content div.layout main').height($('div#content div.layout').height()); // fix ipad mini 03/07/2017

        this.$('.vmap').vectorMap({
            map: 'custom',
            backgroundColor: 'transparent',
            zoomButtons: false,
            zoomMin: 1.5,
            zoomMax: 4,
            regionStyle: {
                // generic style, for shape other than bikes
                initial: {
                    fill: $legend.find('.initial').css('color'),
                    stroke: $legend.find('.initial').css('borderTopColor')
                },
                // selected bike style
                selected: {
                    fill: $legend.find('.selected').css('color'),
                    stroke: $legend.find('.selected').css('borderTopColor')
                }
            },
            regionMargin: 50, // extra stroke around visible shapes
            series: {
                regions: [
                    {
                        values: this.itemStates,
                        attribute: 'fill',
                        scale: {
                            coach: $legend.find('.regions .bike-coach').css('color'),
                            current: $legend.find('.regions .bike-active').css('color'),
                            available: $legend.find('.regions .bike-available').css('color'),
                            unavailable: $legend.find('.regions .bike-unavailable').css('color')
                        }
                    },
                    {
                        values: this.itemStates,
                        attribute: 'stroke',
                        scale: {
                            coach: $legend.find('.regions .bike-coach').css('borderTopColor'),
                            current: $legend.find('.regions .bike-active').css('borderTopColor'),
                            available: $legend.find('.regions .bike-available').css('borderTopColor'),
                            unavailable: $legend.find('.regions .bike-unavailable').css('borderTopColor')
                        }
                    },
                    {
                        values: this.itemStates,
                        attribute: 'stroke-width',
                        scale: {
                            coach: 1,
                            current: 1,
                            available: 1,
                            unavailable: 1
                        }
                    }
                ]
            },
            onRegionTipShow: function (event) {
                event.preventDefault();
            },
            onRegionClick: function (event, code) {
                if (this.itemStates[code] === 'available') {
                    booking.selectedItemToken = code;
                    booking.selectedItemDisplayName = this.itemNames[code];
                }
            }.bind(this)
        });

        // save jvectormap reference
        this.map = this.$('.vmap').vectorMap('get', 'mapObject');

        this.map.setScale(
            0.5,
            this.map.width / 2,
            this.map.height / 2,
            false
          );
    },
    refresh: function() {
        this.itemStates = this.getItemStates();

        _.forEach(this.map.series.regions, function(dataSeries) {
            dataSeries.clear();
            dataSeries.setValues(this.itemStates);
        }.bind(this));
    },
    getItemStates: function () {
        var userBooking = this.model;
        var states = {};

        userBooking.session.layout.items.forEach(function (item) {
          if (item.type.indexOf('coach') !== -1) {
              // it's a coach element
              states[item.token] = "coach";
            } else {
                // it's a user element, handle bookable property
                states[item.token] = item.bookable ? "available" : "unavailable";

                // browse bookings to find current state
                userBooking.session.bookings.forEach(function (sessionBooking) {
                    if (sessionBooking.itemToken !== item.token) {
                        // skip unrelated items
                        return;
                    }

                    if (userBooking.getId() === sessionBooking.getId()) {
                        // this bike is mine!
                        states[item.token] = "current";
                    } else {
                        // this bike is booked
                        states[item.token] = "unavailable";
                    }
                });
            }
        });

        return states;
    },
    getItemNames: function () {
        var userBooking = this.model;
        var names = {};

        userBooking.session.layout.items.forEach(function (item) {
            names[item.token] = item.displayName;
        });

        return names;
    }
});
