var Marionette = require('backbone.marionette');
var HeadingView = require('./template-sidebar/heading');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/template-sidebar.nunj').render(data);
    },
    regions: {
        "sidebar-heading": ".region-sidebar-heading",
    },
    onBeforeShow: function() {
        // initialize subviews
        this.showChildView('sidebar-heading', new HeadingView({
            model: this.model
        }));
    }
});
