// Item Collection - item-collection.js
var AmpCollection = require('ampersand-collection');

module.exports = AmpCollection.extend({
    resetStates: function(ignoredModel) {
        this.each(function(model) {
            if (model !== ignoredModel) {
                model.state = 'normal';
            }
        });
    }
});

// references to child model
module.exports.prototype.model = require('./item');
