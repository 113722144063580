var Marionette = require('backbone.marionette');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../../templates/session-sidebar/heading.nunj').render(data);
    },
    serializeData: function() {
        return {
            session: this.model
        };
    },
    modelEvents: {
        "change": "render"
    }
});
