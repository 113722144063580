var $ = require('jquery');

var ajaxCount = 0;

var updateClass = function($el) {
    if (ajaxCount > 0) {
        $el.addClass('ajax');
    } else {
        $el.removeClass('ajax');
    }
};

module.exports = {
    setElement: function(el) {
        this.$el = $(el);
    },

    wrap: function(xhr) {
        var $el = this.$el || $('body');

        // increase ajax count
        ajaxCount++;
        updateClass($el);

        xhr.addEventListener("loadend", function() {
            // decrease ajax count
            ajaxCount--;
            updateClass($el);
        }, false);

        // return given xhr object
        return xhr;
    }
};
