var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
require( "./macro/marionette.nunj" );
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
env.getTemplate("./macro/marionette.nunj", false, undefined, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("region")) {
var t_4 = t_1.region;
} else {
cb(new Error("cannot import 'region'")); return;
}
context.setVariable("region", t_4);
output += "\n\n";
output += runtime.suppressValue((lineno = 2, colno = 7, runtime.callWrap(t_4, "region", ["sidebar-heading"])), env.opts.autoescape);
output += "\n\n";
output += "\n<div class=\"sidebar-actions template-actions\">\n    <a data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 6, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin/:clientToken/templates"])), env.opts.autoescape);
output += "\">\n        <button class=\"template-change\">Voir un autre template</button>\n    </a>\n</div>\n";
cb(null, output);
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
