// Template Model - template.js
var BaseModel = require('./base/model');

module.exports = BaseModel.extend({
    modelType: 'Template',
    props: {
        createdAt: {type: 'iso-date'},
        name: {type: 'string'},
        maximumBookableItems: {type: 'number'},
        headerText: {type: 'string', default: 'Cliquez sur un vélo pour le sélectionner'},
        helpText: {type: 'string', default: ''}
    },
    session: {
        state: {type: 'string', required: true, values: ['normal', 'selected'], default: 'normal'}
    },
    derived: {
        isSelected: {
            deps: ['state'],
            fn: function() {
                return this.state === 'selected';
            }
        }
    },
    initialize: function() {
        this.listenTo(this, 'change:state', function(model, value) {
            if (value !== 'normal' && model.collection) {
                model.collection.resetStates(model);
            }
        });
    }
});

// references to other models
var _ = require('underscore');

_.extend(module.exports.prototype._children, {
    layout: require('./layout')
});