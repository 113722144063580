// Enhanced objects using promises - promise.js
var Promise = require('any-promise');
var _ = require('underscore');

var extendOptions = function(options, resolve, reject) {
    return _.extend({}, options, {
        success: function() {
            if (options && options.success) {
                options.success.apply(this, arguments);
            }
            resolve.apply(this, arguments);
        },
        error: function() {
            if (options && options.error) {
                options.error.apply(this, arguments);
            }
            reject.apply(this, arguments);
        }
    });
};

module.exports = {
    // promisify fetch method
    extendModel: function(BaseModel) {
        return BaseModel.extend({
            fetch: function(options) {
                var self = this;

                return new Promise(function(resolve, reject) {
                    BaseModel.prototype.fetch.call(self, extendOptions(options, resolve, reject));
                });
            },
            save: function(attrs) {
                var self = this;

                return new Promise(function(resolve, reject) {
                    BaseModel.prototype.save.call(self, attrs, extendOptions({}, resolve, reject));
                });
            }
        });
    },

    // promisify fetch and getOrFetch methods
    extendCollection: function(BaseCollection) {
        return BaseCollection.extend({
            fetch: function(options) {
                var self = this;

                return new Promise(function(resolve, reject) {
                    BaseCollection.prototype.fetch.call(self, extendOptions(options, resolve, reject));
                });
            },
            getOrFetch: function (id, options, cb) {
                var self = this;

                return new Promise(function(resolve, reject) {
                    BaseCollection.prototype.getOrFetch.call(self, id, options || {}, function(err, model) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(model);
                        }

                        if (typeof cb === "function") {
                            cb(err, model);
                        } else if (typeof options === "function") {
                            options(err, model);
                        }
                    });
                });
            }
        });
    }
};