var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
require( "./macro/marionette.nunj" );
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
env.getTemplate("./macro/marionette.nunj", false, undefined, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("region")) {
var t_4 = t_1.region;
} else {
cb(new Error("cannot import 'region'")); return;
}
context.setVariable("region", t_4);
output += "\n\n";
output += "\n<div class=\"title-bar\">\n    <input class=\"form-control\" type=\"text\" name=\"name\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"name", env.opts.autoescape), env.opts.autoescape);
output += "\" />\n    <a class=\"edit\" onclick=\"$(this).parent().children('.form-control').focus();\">Modifier</a>\n    <span class=\"clearfix\"></span>\n    <div>ID : ";
output += runtime.suppressValue((lineno = 7, colno = 29, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"getId", env.opts.autoescape), "template[\"getId\"]", [])), env.opts.autoescape);
output += "</div>\n</div>\n\n";
output += "\n";
output += runtime.suppressValue((lineno = 11, colno = 7, runtime.callWrap(t_4, "region", ["template-map"])), env.opts.autoescape);
output += "\n\n";
output += "\n<div class=\"content-actions\">\n    <button class=\"cto cto-normal\" data-hook=\"submit\">Enregistrer les modifications</button>\n</div>";
cb(null, output);
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
