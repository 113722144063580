var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"modal-body\">\n    <form class=\"form-horizontal\">\n        <div class=\"row\">\n            <label class=\"control-label\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "validationSuccessText"), env.opts.autoescape);
output += ".</label>\n        </div>\n    </form>\n</div>\n<div class=\"modal-footer\">\n    <div class=\"btn-group btn-group-justified\">\n        <div class=\"btn-group\">\n            <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit\">OK</button>\n        </div>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
