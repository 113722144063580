var AmpView = require('ampersand-view');
var VirtualCollection = require('./../../../common/adapter/backbone-virtual-collection');
var app = require('ampersand-app');

var ItemTotalView = AmpView.extend({
    autoRender: true,
    initialize: function() {
        this.listenTo(this.collection, 'add remove', this.render);
    }
});

var ItemView = AmpView.extend({
    autoRender: true,
    initialize: function() {
        this.model.session.fetchOnce();
        this.listenTo(this.model, 'change', this.render);
    },
    events: {
        "click [data-hook=action-move]": function(event) {
            event.preventDefault();
            event.handled = true;

            this.parent.trigger('item:move', this.model);
        },
        "click [data-hook=action-eject]": function(event) {
            event.preventDefault();
            event.handled = true;

            this.parent.trigger('item:eject', this.model);
        },
        "click [data-hook=action-assign]": function(event) {
            event.preventDefault();
            event.handled = true;

            this.parent.trigger('item:assign', this.model);
        },
        "click [data-load]": function(event) {
            event.handled = true;
        },
        // must be the last event listener
        "click": function(event) {
            if (event.handled) {
                delete event.handled;
            } else {
                event.preventDefault();

                app.trigger('select:booking', this.model, !this.model.isSelected);
            }
        }
    }
});

/**
 * "assigned" item view
 */
var AssignedTotalView = ItemTotalView.extend({
    template: function() {
        var totalAssignedBookings = this.collection.length;
        var maximumBookableItems = this.model.maximumBookableItems;
        return "<span class=\"pull-right\">" + totalAssignedBookings + " réservées sur " + maximumBookableItems + "</span>";
    }
});

var AssignedItemView = ItemView.extend({
    template: function() {
        return require('../../templates/session-sidebar/booking-assigned.nunj').render({
            booking: this.model,
            editable: this.parent.editable
        });
    }
});

/**
 * "not assigned" item view
 */
var NotAssignedTotalView = ItemTotalView.extend({
    template: function() {
        var totalNotAssignedBookings = this.collection.length;
        return "<span class=\"pull-right\">" + totalNotAssignedBookings + " réservations</span>";
    }
});

var NotAssignedItemView = ItemView.extend({
    template: function() {
        return require('../../templates/session-sidebar/booking-not-assigned.nunj').render({
            booking: this.model,
            editable: this.parent.editable
        });
    }
});

/**
 * List view
 */
module.exports = AmpView.extend({
    template: function() {
        return require('../../templates/session-sidebar/list-bookings.nunj').render({
            session: this.model,
            editable: this.editable
        });
    },
    subviews: {
        totalAssigned: {
            hook: 'total-assigned',
            waitFor: 'model.bookings',
            prepareView: function(el) {
                return new AssignedTotalView({
                    el: el,
                    model: this.model,
                    collection: this.assignedBookings
                });
            }
        },
        totalNotAssigned: {
            hook: 'total-not-assigned',
            waitFor: 'model.bookings',
            prepareView: function(el) {
                return new NotAssignedTotalView({
                    el: el,
                    model: this.model,
                    collection: this.notAssignedBookings
                });
            }
        }
    },
    initialize: function(options) {
        this.editable = !!options.editable;

        // build virtual collections
        this.assignedBookings = new VirtualCollection(this.model.bookings, {
            filter: function(booking) {
                return booking.isAssigned;
            }
        });
        this.notAssignedBookings = new VirtualCollection(this.model.bookings, {
            filter: function(booking) {
                return !booking.isAssigned;
            }
        });

        // attach action listeners
        this.listenTo(this, 'item:move', this.onMoveItem);
        this.listenTo(this, 'item:eject', this.onEjectItem);
        this.listenTo(this, 'item:assign', this.onMoveItem);
    },
    render: function() {
        this.renderWithTemplate();
        this.renderCollection(this.assignedBookings, AssignedItemView, this.queryByHook('list-assigned'));
        this.renderCollection(this.notAssignedBookings, NotAssignedItemView, this.queryByHook('list-not-assigned'));
    },
    onMoveItem: function(booking) {
        app.trigger('select:booking', booking, true);
        booking.state = 'moving';
    },
    onEjectItem: function(booking) {
        app.trigger('select:booking', booking, true);
        booking.state = 'ejecting';
    }
});
