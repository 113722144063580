var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
require( "./macro/marionette.nunj" );
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
env.getTemplate("./macro/marionette.nunj", false, undefined, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("region")) {
var t_4 = t_1.region;
} else {
cb(new Error("cannot import 'region'")); return;
}
context.setVariable("region", t_4);
output += "\n\n<div class=\"vmap\"></div>\n<div class=\"vmap-controls\">\n    <div class=\"btn-group-vertical\">\n        <button class=\"vmap-zoomin btn btn-sm btn-info\"><i class=\"fa fa-plus fa-lg\"></i></button>\n        <button class=\"vmap-zoomout btn btn-sm btn-info\"><i class=\"fa fa-minus fa-lg\"></i></button>\n    </div>\n</div>\n\n";
output += runtime.suppressValue((lineno = 10, colno = 7, runtime.callWrap(t_4, "region", ["helpers-eject"])), env.opts.autoescape);
output += "\n";
output += runtime.suppressValue((lineno = 11, colno = 7, runtime.callWrap(t_4, "region", ["helpers-move"])), env.opts.autoescape);
output += "\n";
cb(null, output);
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
