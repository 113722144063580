/*jshint browser: true*/

var Marionette = require('backbone.marionette');
var LayoutMapView = require('./layout-map-printable');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/page-session-print.nunj').render(data);
    },
    serializeData: function () {
        return {
            session: this.model,
            bookings: this.model.bookings.filter(function(booking) {
                return booking.itemToken;
            })
        };
    },
    regions: {
        "session-map": ".region-session-map"
    },
    onBeforeShow: function() {
        this.showChildView('session-map', new LayoutMapView({
            model: this.model.layout
        }));
    },
    onAttach: function() {
        setTimeout(function() {
            window.print();
        }, 500);
    }
});