var Marionette = require('backbone.marionette');
var LayoutMapView = require('./layout-map');
require('form-serializer');

module.exports = Marionette.LayoutView.extend({
    tagName: "form",
    className: "form-inline",
    template: function (data) {
        return require('../templates/template-form.nunj').render(data);
    },
    serializeData: function () {
        return {
            template: this.model
        };
    },
    regions: {
        "template-map": ".region-template-map"
    },
    onBeforeShow: function() {
        // initialize subviews
        this.showChildView('template-map', new LayoutMapView({
            model: this.model.layout
        }));
    },
    events: {
        "click [data-hook=submit]": "onSubmit"
    },
    onSubmit: function (event) {
        event.preventDefault();

        var values = this.$el.serializeObject();

        if (!this.model.validate(values)) {
            this.model.save(values, {wait: true});
        } else {
            throw this.model.validationError;
        }
    }
});
