var Marionette = require('backbone.marionette');
var AmpObject = require('ampersand-object');
var NavbarView = require('./navbar');
var SessionListView = require('./session-list');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/page-session-list.nunj').render(data);
    },
    regions: {
        "navbar": ".region-navbar",
        "session-list": ".region-session-list"
    },
    onBeforeShow: function() {
        // initialize subviews
        this.showChildView('navbar', new NavbarView({
            model: new AmpObject({active: "sessions"})
        }));
        this.showChildView('session-list', new SessionListView({
            collection: this.collection
        }));
    }
});