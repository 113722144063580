var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var macro_t_1 = runtime.makeMacro(
["name"], 
[], 
function (l_name, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
var t_2 = "";t_2 += "\n    <div class=\"region-";
t_2 += runtime.suppressValue(l_name, env.opts.autoescape);
t_2 += "\"></div>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("region");
context.setVariable("region", macro_t_1);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
