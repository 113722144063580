var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "\n<nav class=\"navbar navbar-default\">\n    <div class=\"container-fluid\">\n\n        <div class=\"navbar-header\">\n            <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#navbar-collapse\">\n                <span class=\"sr-only\">Navigation</span>\n                <span class=\"icon-bar\"></span>\n                <span class=\"icon-bar\"></span>\n                <span class=\"icon-bar\"></span>\n            </button>\n\n            <a class=\"navbar-brand\" data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 12, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin/:clientToken"])), env.opts.autoescape);
output += "\">\n                <span class=\"logo hidden-ajax\"><img src=\"/img/logo.png\" /></span>\n                <span class=\"logo visible-ajax\"><i class=\"fa fa-spinner fa-pulse fa-lg\"></i></span>\n                <span class=\"separator\"></span>\n                <span class=\"title\"><strong>Biking</strong> administrator</span>\n            </a>\n        </div>\n\n        <div class=\"collapse navbar-collapse\" id=\"navbar-collapse\">\n            <ul class=\"nav navbar-nav navbar-right\">\n                <li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "active") == "sessions"?"active":""), env.opts.autoescape);
output += "\">\n                    <a data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 23, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin/:clientToken/sessions"])), env.opts.autoescape);
output += "\">Sessions</a>\n                </li>\n                <li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "active") == "templates"?"active":""), env.opts.autoescape);
output += "\">\n                    <a data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 26, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin/:clientToken/templates"])), env.opts.autoescape);
output += "\">Templates</a>\n                </li>\n            </ul>\n        </div>\n\n    </div>\n</nav>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
