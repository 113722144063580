var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div>\n    ";
output += "\n    <h3>Session en cours</h3>\n    <div class=\"session-list row\" data-hook=\"session-current\"></div>\n\n    <h3>Session à venir</h3>\n    <div class=\"session-list row\" data-hook=\"session-coming\"></div>\n\n    <h3>Retrouver une session</h3>\n    <form class=\"filter-group\">\n        <div class=\"filter\">\n            <input type=\"date\" name=\"startedAt\" class=\"form-control\" placeholder=\"Date\" />\n        </div>\n        <div class=\"filter\">\n            <input type=\"search\" name=\"name\" class=\"form-control\" placeholder=\"Nom de la session\" />\n        </div>\n    </form>\n    <div class=\"session-list row\" data-hook=\"session-all\"></div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
