var nunjucks = require('nunjucks');
var generateUrl = require('./helpers/generate-url');
var localizedDate = require('./helpers/localized-date');

nunjucks.env = new nunjucks.Environment();

// add template functions
nunjucks.env.addGlobal('generateUrl', generateUrl);

// add template filters
nunjucks.env.addFilter('toDateString', localizedDate.toDateString);
nunjucks.env.addFilter('toTimeString', localizedDate.toTimeString);
