/*jshint browser: true*/

// initialize promises
require('any-promise/register/bluebird');

// enhance nunjucks templates
require('./common/nunjucks-env');

var $ = require('jquery');
var app = require('ampersand-app');
var appAccessToken;
var ClientOAuth2 = require('client-oauth2');
var config = require('./config');
var DocumentView = require('./common/views/document.js');
var Router = require('./router');
app.classLoaded = false;

// locale FR
require('moment');
require('moment/locale/fr');

var getRedirectUri = function() {
    var redirectUri = window.location.protocol + '//' + window.location.host + window.location.pathname;

    // if routes begin with a # and not / then add it to redirectUri
    if (window.location.hash.substr(1)) {
        redirectUri = redirectUri + '#' + window.location.hash.substr(1);
    }

    return redirectUri;
};

// Here we could certainly *chose* to attach it to
// window for better debugging in the browser
// but it's no longer necessary for accessing the
// app instance from other modules.
app.extend({
    init: function () {
        // Initialization sequence
        this.initModels();
        this.initRootView();
        this.initRouter();
    },

    /*jshint singleGroups: false*/
    initModels: function() {
        // Create model collections
        this.bookings = new (require('./common/models/booking-collection'))();
        this.sessions = new (require('./common/models/session-collection'))();
        this.templates = new (require('./common/models/template-collection'))();
    },

    initRootView: function() {
        // Create and fire up the root view
        this.rootView = new DocumentView({
            el: document.body
        });
        this.rootView.render();
    },

    initRouter: function() {
        // Create and fire up the router
        this.router = new Router();
        this.router.history.start({
            pushState: true,
            hashChange: false
        });
    },

    preExecute: function (clientToken) {
        // update config client token
        config.api.client_token = clientToken;

        // add client theme to head of DOM
        if (!app.classLoaded) {
            document.head.innerHTML+= '<link href="/css/' + clientToken + '.css" rel="stylesheet" />';
            app.classLoaded = true;
        }
    }
});

if (config.oauth.enabled) {
    var redirectUri = getRedirectUri();
    var clientAuth = new ClientOAuth2({
        clientId: config.oauth.client_id,
        clientSecret: config.oauth.client_secret,
        accessTokenUri: config.oauth.token_url,
        authorizationUri: config.oauth.auth_url,
        authorizationGrants: ['credentials'],
        redirectUri: redirectUri,
        scopes: []
    });

    var pathname = window.location.pathname;
    var isAdminPage = false;

    if (pathname.match(/^\/admin\//)) {
        isAdminPage = true;
    }

    if (!isAdminPage && config.api.authorization_header) {
        app.ajaxConfig = {
            headers: {'Authorization':'Bearer ' + config.api.authorization_header}
        };

        $(function() {
            app.init();
        });
    } else if (isAdminPage && !appAccessToken) {
        clientAuth.code.getToken(window.location.href).then(function (createdToken) {
            appAccessToken = createdToken;

            // save headers to use it later
            app.ajaxConfig = {
                headers: { 'Authorization': 'Bearer ' + appAccessToken.accessToken }
            };

            $(function() {
                app.init();
            });
        // if cant get token (no code or code invalid/expired) then redirect to auth url
        }).catch(function() {
            $(window).attr('location', clientAuth.code.getUri());
        });
    } else {
        $(function() {
            app.init();
        });
    }
} else {
    if (config.api.authorization_header) {
        app.ajaxConfig = {
            headers: {'Authorization':'Bearer ' + config.api.authorization_header}
        };
    }

    $(function() {
        app.init();
    });
}
