var VirtualCollection = require('backbone-virtual-collection');
var _ = require('underscore');

module.exports = VirtualCollection.extend({
    isCollection: true,

    // patch indexOf function
    // > fixed a really annoying bug about wrong event managment
    // > updated models would not be removed from filtered collection
    indexOf: function(model) {
        _.indexOf(this.models, model);
    }
});