// Base Collection - collection.js
var AmpCollection = require('ampersand-rest-collection');
var Hydra = require('ampersand-hydra-mixins');
var PromiseCollection = require('./promise-wrapper').extendCollection;
var ajaxWrapper = require('../../ajax').wrap;
var app = require('ampersand-app');
var config = require('./../../../config');

module.exports = PromiseCollection(AmpCollection.extend(Hydra.CollectionMixin, {
    sync: function() {
        return ajaxWrapper(AmpCollection.prototype.sync.apply(this, arguments));
    },
    initialize: function () {
        this.listenTo(this, "destroy", function (model) {
            this.remove(model);
        });
    },
    fetch: function(userOptions) {
        var options = this.fetchOptions(userOptions);

        return AmpCollection.prototype.fetch.call(this, options);
    },
    // helper to fetch the collection only once
    fetchOnce: function() {
        var xhr = this.fetch();
        this.fetchOnce = function() {
            return xhr;
        };
        return xhr;
    },
    // use app ajaxConfig
    ajaxConfig: function () {
        return app.ajaxConfig;
    },
    urlRoot: function() {
        return config.api.base_url;
    }
}));
