var Marionette = require("backbone.marionette");
var tpl = require("../templates/header.nunj");
var config = require('./../../config');

module.exports = Marionette.ItemView.extend({
    template: function(data) {
        return tpl.render(data);
    },
    serializeData: function () {
        return {
            clientToken: config.api.client_token,
            session: this.model.session,
            bookingDisplayName: this.getBookingDisplayName(),
            headerText: this.model.session.template.headerText,
            helpText: this.model.session.template.helpText
        };
    },
    getBookingDisplayName: function () {
        var bookingDisplayName;

        if (this.model.givenName && this.model.familyName) {
            bookingDisplayName = this.model.givenName + ' ' + this.model.familyName;
        } else if (this.model.givenName) {
            bookingDisplayName = this.model.givenName;
        } else if (this.model.familyName) {
            bookingDisplayName = this.model.familyName;
        } else {
            bookingDisplayName = this.model.displayName;
        }

        return bookingDisplayName;
    }
});