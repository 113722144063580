var Marionette = require('backbone.marionette');
var AmpObject = require('ampersand-object');
var NavbarView = require('./navbar');
var SessionSidebarView = require('./session-sidebar');
var SessionMapView = require('./session-map');
var _ = require('underscore');
var app = require('ampersand-app');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/page-session.nunj').render(data);
    },
    regions: {
        "navbar": ".region-navbar",
        "session-sidebar": ".region-session-sidebar",
        "session-map": ".region-session-map"
    },
    initialize: function() {
        this.listenTo(app, 'select:booking', this.selectBooking);
        this.listenTo(app, 'select:item', this.selectItem);
    },
    onBeforeShow: function() {
        var renderSessionMap = _.debounce(function() {
            this.showChildView('session-map', new SessionMapView({
                model: this.model
            }));
        }.bind(this));

        // initialize subviews
        this.showChildView('navbar', new NavbarView({
            model: new AmpObject({active: "sessions"})
        }));
        this.showChildView('session-sidebar', new SessionSidebarView({
            model: this.model
        }));

        renderSessionMap();

        // event listeners
        this.listenTo(this.model.layout, 'change', renderSessionMap);
        this.listenTo(this.model.layout.items, 'add remove', renderSessionMap);
    },
    selectBooking: function(_booking, value) {
        var booking = this.model.bookings.get(_booking.getId());
        booking.state = value ? 'selected' : 'normal';

        // try to link selected state with item
        var bookingItem = this.model.layout.items.get(booking.itemToken);

        if (bookingItem) {
            bookingItem.state = value ? 'selected' : 'normal';
        } else {
            this.model.layout.items.resetStates();
        }
    },
    selectItem: function(_item, value) {
        var item = this.model.layout.items.get(_item.getId());
        item.state = value ? 'selected' : 'normal';

        // try to link selected state with booking
        var itemBooking = this.model.bookings.get(item.token, 'itemToken');

        if (itemBooking) {
            itemBooking.state = value ? 'selected' : 'normal';
        } else {
            this.model.bookings.resetStates();
        }
    }
});