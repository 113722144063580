var Marionette = require("backbone.marionette");
var vectorMap = require('./../../map/lib/vectormap');
var $ = require('jquery');

// shapes
var bike = require('resources/svg/bike');
var step = require('resources/svg/step');
var punchingBag = require('resources/svg/punching-bag');
var yogaMat = require('resources/svg/yoga-mat');
var treadmill = require('resources/svg/treadmill');
var triangle = require('resources/svg/triangle');
var rectangle = require('resources/svg/rectangle');
var circle = require('resources/svg/circle');
var items = {
  'coach bike': bike,
  'bike': bike,
  'step': step,
  'coach step': step,
  'punching bag': punchingBag,
  'coach punching bag': punchingBag,
  'yoga mat': yogaMat,
  'coach yoga mat': yogaMat,
  'treadmill': treadmill,
  'coach treadmill': treadmill,
  'triangle': triangle,
  'coach triangle': triangle,
  'rectangle': rectangle,
  'coach rectangle': rectangle,
  'circle': circle,
  'coach circle': circle
};

module.exports = Marionette.LayoutView.extend({
    className: "vmap-container",
    template: function(data) {
        return require("../templates/layout-map.nunj").render(data);
    },
    initialize: function() {
        var layout = this.model;

        // add elements
        var builder = vectorMap.getBuilder(layout.width, layout.height);

        layout.items.forEach(function(item) {
            builder.addShape(items[item.type], [item.posX, item.posY, item.rotZ], item.displayName, item.token);
        });

        builder.save('custom');
    },
    onAttach: function() {
        this.itemStates = this.getItemStates();
        var $legend = $('.legend');

        // freeze vmap size
        this.$('.vmap').width(this.model.width);
        this.$('.vmap').height(this.model.height);

        // render vmap
        this.$('.vmap').vectorMap({
            map: 'custom',
            backgroundColor: 'transparent',
            zoomButtons: false,
            zoomOnScroll: false,
            regionStyle: {
                // generic style, for shape other than bikes
                initial: {
                    fill: $legend.find('.initial').css('color'),
                    stroke: $legend.find('.initial').css('borderTopColor')
                }
            },
            regionLabelStyle: {
                // generic style, for shape other than bikes
                initial: {
                    'font-family': 'Arial',
                    'font-size': '30'
                }
            },
            series: {
                regions: [
                    {
                        values: this.itemStates,
                        attribute: 'fill',
                        scale: {
                            coach: $legend.find('.regions .bike-coach').css('color'),
                            active: $legend.find('.regions .bike-active').css('color'),
                            available: $legend.find('.regions .bike-available').css('color'),
                            unavailable: $legend.find('.regions .bike-unavailable').css('color')
                        }
                    },
                    {
                        values: this.itemStates,
                        attribute: 'stroke',
                        scale: {
                            coach: $legend.find('.regions .bike-coach').css('borderTopColor'),
                            active: $legend.find('.regions .bike-active').css('borderTopColor'),
                            available: $legend.find('.regions .bike-available').css('borderTopColor'),
                            unavailable: $legend.find('.regions .bike-unavailable').css('borderTopColor')
                        }
                    },
                    {
                        values: this.itemStates,
                        attribute: 'stroke-width',
                        scale: {
                            coach: 1,
                            active: 1,
                            available: 1,
                            unavailable: 1
                        }
                    }
                ]
            },
            labels: {
                regions: {
                    render: this.onRenderLabel.bind(this)
                }
            },
            onRegionTipShow: function(event) {
                event.preventDefault();
            }
        });

        // freeze vmap dom
        var $originalSvg = this.$('.vmap svg');
        var $frozenSvg = $originalSvg.clone().addClass('frozen');
        $frozenSvg.removeAttr('width').removeAttr('height');
        $frozenSvg.get(0).setAttribute('viewBox', '0 0 '+this.model.width+' '+this.model.height);
        //$frozenSvg.get(0).setAttribute('preserveAspectRatio', 'xMidYMid meet');

        this.$('.vmap').removeAttr('style');
        $originalSvg.replaceWith($frozenSvg);
    },
    onRenderLabel: function(code) {
        var item = this.model.items.get(code);

        return item.displayName;
    },
    getItemStates: function() {
        var layout = this.model;
        var states = {};

        layout.items.forEach(function(item) {
            if (item.type.indexOf('coach') !== -1) {
                // it's a coach bike
                states[item.token] = "coach";
            } else {
                // it's a user bike, handle bookable property
                states[item.token] = item.bookable ? "available" : "unavailable";
            }
        });

        return states;
    }
});