var AmpView = require('ampersand-view');
var VirtualCollection = require('./../../../common/adapter/backbone-virtual-collection');
var $ = require('jquery');
var app = require('ampersand-app');

var ItemTotalView = AmpView.extend({
    autoRender: true
});

var ItemView = AmpView.extend({
    initialize: function() {
        this.listenTo(this.model, 'change:state', this.refresh);
    },
    events: {
        "click .switch": function(event) {
            event.handled = true;
            this.model.toggle('bookable');
        },
        "click [data-load]": function(event) {
            event.handled = true;
        },
        // must be the last event listener
        "click": function(event) {
            if (event.handled) {
                delete event.handled;
            } else {
                event.preventDefault();

                app.trigger('select:item', this.model, !this.model.isSelected);
            }
        }
    },
    render: function() {
        this.renderWithTemplate();

        setTimeout(function() {
            var $toggle = $(this.el).find('[data-toggle="toggle"]');
            $toggle.bootstrapToggle();
        }.bind(this), 0);
    },
    refresh: function() {
        var li = this.query('li');

        li.classList.toggle('active', this.model.state.isSelected);
    }
});

/**
 * "bike" item view
 */
var BikeTotalView = ItemTotalView.extend({
    template: function() {
        var bookableBikes = this.collection.where({bookable: true}).length;
        var totalBikes = this.collection.length;
        return "<span class=\"pull-right\">" + bookableBikes + " actifs sur " + totalBikes + "</span>";
    }
});

var BikeItemView = ItemView.extend({
    template: function() {
        return require('../../templates/session-sidebar/bike.nunj').render({
            item: this.model
        });
    }
});

/**
 * List view
 */
module.exports = AmpView.extend({
    template: function() {
        return require('../../templates/session-sidebar/list-bikes.nunj').render({
            session: this.model
        });
    },
    subviews: {
        totalBike: {
            hook: 'total-bikes',
            waitFor: 'model.bookings',
            prepareView: function(el) {
                return new BikeTotalView({
                    el: el,
                    collection: this.bikeItems
                });
            }
        }
    },
    initialize: function() {
        // build virtual collections
        this.bikeItems = new VirtualCollection(this.model.layout.items, {
            filter: function(item) {
                return item.type.indexOf('coach') === -1 && item.displayName.indexOf('n°') !== -1;
            }
        });

        // handle model saving delegation
        this.listenTo(this.model.layout.items, 'change:bookable', function() {
            this.model.save();
        });
    },
    render: function() {
        this.renderWithTemplate();
        this.renderCollection(this.bikeItems, BikeItemView, this.queryByHook('list-bikes'));
    }
});
