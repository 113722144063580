var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"header-main\">\n    <div class=\"brand-logo\"></div>\n    <h2><span class=\"session-name ";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape)), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape), env.opts.autoescape);
output += "</span> <span class=\"booking-display-name\">(";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "bookingDisplayName"), env.opts.autoescape);
output += ")</span> ";
if(runtime.contextOrFrameLookup(context, frame, "helpText")) {
output += "<i class=\"fa fa-info-circle help-header\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "helpText"), env.opts.autoescape);
output += "\"></i>";
;
}
output += "</h2>\n    <ul class=\"list-inline\">\n        <li><i class=\"fa fa-calendar\"></i> ";
output += runtime.suppressValue(env.getFilter("toDateString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</li>\n        <li><i class=\"fa fa-clock-o\"></i> ";
output += runtime.suppressValue(env.getFilter("toTimeString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</li>\n    </ul>\n</div>\n<div class=\"header-info\">\n    <p>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headerText"), env.opts.autoescape);
output += "</p>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
