var Router = require('ampersand-router');
var app = require('ampersand-app');
var mapController = require('./map/controller');
var adminController = require('./admin/controller');
var Marionette = require('backbone.marionette');
var _ = require('underscore');

module.exports = Router.extend({
    routes: {
        // map module
        ":clientToken/bookings/:bookingToken": mapController.showBooking,             // #cmg/bookings/VafLHkih
        // admin module
        "admin/:clientToken": 'redirectToSession',                                    // #admin/cmg
        "admin/:clientToken/sessions": adminController.listSessions,                  // #admin/cmg/sessions
        "admin/:clientToken/sessions/:sessionId": adminController.showSession,        // #admin/cmg/sessions/1
        "admin/:clientToken/sessions/:sessionId/print": adminController.printSession, // #admin/cmg/sessions/1/print
        "admin/:clientToken/templates": adminController.listTemplates,                // #admin/cmg/templates
        "admin/:clientToken/templates/:templateId": adminController.showTemplate,     // #admin/cmg/templates/1
        // errors
        "*path": "displayNotFound"
    },
    redirectToSession: function (clientToken) {
        return this.redirectTo('admin/' + clientToken + '/sessions');
    },
    displayNotFound: function() {
        app.rootView.showChildView("content", new Marionette.ItemView({
            template: function() {
                return "<h1>404: Not Found</h1>";
            }
        }));
    },
    generate: function(route, params) {
        var url = route;

        _.each(params, function(value, param) {
            url = url.replace(':'+param, value);
        });

        return url;
    }
});