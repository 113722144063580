var Marionette = require('backbone.marionette');
module.exports = Marionette.ItemView.extend({
    template: function(data) {
        return require('../../templates/session-sidebar/index.nunj').render(data);
    },
    serializeData: function () {
        return {
            session: this.model
        };
    }
});
