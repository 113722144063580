// Base Model - model.js
var AmpUniqueModel = require('ampersand-unique-model');
var Hydra = require('ampersand-hydra-mixins');
var Promise = require('any-promise');
var PromiseModel = require('./promise-wrapper').extendModel;
var ajaxWrapper = require('../../ajax').wrap;
var app = require('ampersand-app');
var config = require('./../../../config');

module.exports = PromiseModel(AmpUniqueModel.extend(Hydra.ModelMixin, {
    sync: function() {
        return ajaxWrapper(AmpUniqueModel.prototype.sync.apply(this, arguments));
    },
    session: Hydra.props(),
    derived: {
        id: {
            cache: false,
            fn: function() {
                return this.getId();
            }
        }
    },
    validate: function(attrs) {
        return this.validateRequired(attrs);
    },
    // Check that all required attributes are present
    validateRequired: function(attrs) {
        for (var def in this._definition) {
            if (!this._definition[def].required) {
                continue;
            }

            var value = attrs.hasOwnProperty(def) ? attrs[def] : this.get(def);

            if (typeof value === 'undefined') {
                return "Missing required " + def + " value.";
            } else if (value === '') {
                return "Empty required " + def + " value.";
            }
        }
    },
    // helper to fetch the model only once
    fetchOnce: function() {
        if (this.isNew()) {
            return new Promise(function() {
                // do nothing
            });
        }

        // this.hasChanged(['id']) doesn't work outside of change events
        // @link https://ampersandjs.com/docs/#ampersand-state-haschanged
        var idHasChanged = this._previousAttributes[this.idAttribute] !== this._values[this.idAttribute];

        if (!this._fetchPromise || idHasChanged) {
            this._fetchPromise = this.fetch();
        }

        var fetchPromise = this._fetchPromise;

        return new Promise(function(resolve, reject) {
            fetchPromise.then(resolve, reject);
        });
    },
    // use app ajaxConfig
    ajaxConfig: function () {
        return app.ajaxConfig;
    },
    dataTypes: {
        "iso-date": {
            set: function(newVal) {
                var date = new Date(newVal);
                try {
                    return { type: "iso-date", val: date.toISOString() };
                } catch(error) {
                    return { type: "invalid", val: newVal };
                }
            },
            get: function(val) {
                return new Date(val);
            }
        }
    },
    urlRoot: function() {
        return config.api.base_url;
    }
}));
