var Marionette = require('backbone.marionette');
var app = require('ampersand-app');
require('form-serializer');

module.exports = Marionette.ItemView.extend({
    className: "modal-content",
    template: function (data) {
        return require('../../templates/modal/change-layout.nunj').render(data);
    },
    serializeData: function() {
        return {
            session: this.model,
            templates: app.templates
        };
    },
    events: {
        "click [data-hook=submit]": "onSubmit"
    },
    onSubmit: function (event) {
        event.preventDefault();

        var values = this.model.parse(this.$('form').serializeObject());

        if (!this.model.validate(values)) {
            this.model.set(values);
            this.model.save().then(function(session) {
                session.bookings.fetch();
                this.trigger('close');
            }.bind(this));
        } else {
            throw this.model.validationError;
        }
    }
});