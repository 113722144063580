var $ = require('jquery');
var svgConverter = require('jqvmap-svgconverter');
var SvgBuilder = require('./svgbuilder.js');

// load jquery plugins
require('jvectormap-next')($);

module.exports = {
    addMap: function(name, options) {
        $.fn.vectorMap('addMap', name, options);
    },

    addSvg: function(name, svg) {
        this.addMap(name, svgConverter.convertString(svg));
    },

    addDom: function(name, dom) {
        this.addMap(name, svgConverter.convertDom(dom));
    },

    getBuilder: function(width, height) {
        var svgBuilder = new SvgBuilder(width, height);

        svgBuilder.save = function(name) {
            this.addDom(name, svgBuilder.value());
        }.bind(this);

        return svgBuilder;
    }
};
