var Marionette = require('backbone.marionette');

module.exports = Marionette.ItemView.extend({
    events: {
        "click .btn-primary": "closePopup"
    },
    template: function () {
        return require('../templates/modal/fail-layout.nunj').render();
    },
    closePopup: function() {
        this.trigger('close');
    }
});