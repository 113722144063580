var Marionette = require("backbone.marionette");
var tpl = require("../templates/document.nunj");
var _ = require('underscore');
var app = require('ampersand-app');
var config = require('./../../config');

module.exports = Marionette.LayoutView.extend({
    tagName: "body",
    template: function(data) {
        return tpl.render(data);
    },
    regions: {
        content: "#content",
        modal: "#modal"
    },
    events: {
        "click a[href='#']": function(event) {
            event.preventDefault();
            if (config.devmode) {
                console.error('Uncaught ' + event.type + ":", event.currentTarget);
            }
        },
        "click a[data-pushstate]": function(event) {
            event.preventDefault();
            app.router.navigate(event.currentTarget.getAttribute('href'));
        }
    },
    /**
     * Open a view in a modal
     *
     * Attach a view to modal region
     * and trigger modal initialization
     */
    openModal: function(view, options) {
        var modalRegion = this.getRegion('modal');
        var modalOptions = _.extend({ backdrop: 'static'}, options);

        modalRegion.show(view);

        var $modal = this.$('.modal').modal(modalOptions);

        view.on('close', function() {
            $modal.modal('hide');
        });

        $modal.on('hidden.bs.modal', function() {
            modalRegion.empty();
        });
    }
});