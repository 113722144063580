var AmpView = require('ampersand-view');

var ItemView = AmpView.extend({
    template: function() {
        return require('../templates/template-list-item.nunj').render({
            template: this.model
        });
    },
    initialize: function() {
        this.listenTo(this.model, 'change', this.render);
    },
    render: function() {
        this.renderWithTemplate();
    }
});

module.exports = AmpView.extend({
    template: function() {
        return require('../templates/template-list.nunj').render();
    },
    initialize: function() {
        this.listenTo(this, 'select', this.selectModel);
    },
    render: function() {
        this.renderWithTemplate();
        this.renderCollection(this.collection, ItemView, "ul");
    },
    selectModel: function(targetModel) {
        if (targetModel) {
            targetModel.state = 'selected';
        } else {
            this.collection.resetStates();
        }
    }
});