var Marionette = require("backbone.marionette");
var tpl = require("../templates/footer.nunj");
var app = require('ampersand-app');
var ChangeLayoutView = require('./fail-modal');
var ConfirmLayoutView = require('./confirm-modal');

module.exports = Marionette.ItemView.extend({
    template: function(data) {
        return tpl.render(data);
    },
    serializeData: function () {
      return {
        validationActionText: this.model.item.validationActionText
      };
    },
    events: {
        "click .btn-danger": function() {
            this.model.selectedItemToken = null;
            this.model.selectedItemDisplayName = null;
        },
        "click .btn-info": function() {
            var self = this;

            if (this.model.selectedItemToken) {
              this.model.itemToken = this.model.selectedItemToken;
              this.model.itemDisplayName = this.model.selectedItemDisplayName;
            }
                this.model.save({}).then(
                    function() {
                        // open confirm popup
                        app.rootView.openModal(new ConfirmLayoutView({
                          model: self.model
                        }));
                        self.model.session.bookings.fetch();
                    },
                    function() {
                        app.rootView.openModal(new ChangeLayoutView({
                            model: self.model
                        }));
                        self.model.session.bookings.fetch();
                    }
                );
        }
    }
});