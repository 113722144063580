// Template Collection - template-collection.js
var BaseCollection = require('./base/collection');
var config = require('./../../config');

module.exports = BaseCollection.extend({
    url: function() {
        return config.api.base_url + '/' + config.api.client_token + '/templates';
    },
    resetStates: function(ignoredModel) {
        this.each(function(model) {
            if (model !== ignoredModel) {
                model.state = 'normal';
            }
        });
    }
});

// references to child model
module.exports.prototype.model = require('./template');
