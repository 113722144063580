var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div>\n    <div class=\"sidebar-back\">\n        <span><a data-load=\"index\"><i class=\"fa fa-chevron-left\"></i></a></span>\n        <span>Activer / désactiver un élément</span>\n    </div>\n\n    <div class=\"sidebar-scrollable\">\n        <div class=\"sidebar-menu\">\n            <div class=\"menu-group\">\n                Liste des éléments <span data-hook=\"total-bikes\"></span>\n            </div>\n            <ul class=\"menu-list\" data-hook=\"list-bikes\"></ul>\n        </div>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
