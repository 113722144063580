var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<li class=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"isSelected", env.opts.autoescape)?"active":""), env.opts.autoescape);
output += "\">\n    <span class=\"id\">\n        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"displayName", env.opts.autoescape), env.opts.autoescape);
output += "\n    </span>\n    <span class=\"switch\">\n        <input type=\"checkbox\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"bookable", env.opts.autoescape)?"checked":""), env.opts.autoescape);
output += " data-toggle=\"toggle\" data-on=\"Activé\" data-off=\"Désactivé&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\" />\n    </span>\n</li>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
