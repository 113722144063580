var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"session-box\">\n    <div class=\"session-title\">\n        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"session-informations\">\n        <div class=\"table-row\">\n            <span class=\"table-cell\"><i class=\"fa fa-calendar\"></i> ";
output += runtime.suppressValue(env.getFilter("toDateString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape),"short"), env.opts.autoescape);
output += "</span>\n            <span class=\"table-cell\"><i class=\"fa fa-clock-o\"></i> ";
output += runtime.suppressValue(env.getFilter("toTimeString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</span>\n        </div>\n    </div>\n    <div class=\"session-footer\">\n        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalAssignedBookings", env.opts.autoescape), env.opts.autoescape);
output += " places réservées sur ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"maximumBookableItems", env.opts.autoescape), env.opts.autoescape);
output += "\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalBookings", env.opts.autoescape) > runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalAssignedBookings", env.opts.autoescape)) {
output += "\n            (+";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalBookings", env.opts.autoescape) - runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalAssignedBookings", env.opts.autoescape), env.opts.autoescape);
output += ")\n        ";
;
}
output += "\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
