var Marionette = require('backbone.marionette');
var AmpObject = require('ampersand-object');
var NavbarView = require('./navbar');
var TemplateListView = require('./template-list');
var TemplateFormView = require('./template-form');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/page-template-list.nunj').render(data);
    },
    regions: {
        "navbar": ".region-navbar",
        "template-list": ".region-template-list",
        "template-form": ".region-template-form"
    },
    onBeforeShow: function() {
        var self = this;

        // initialize subviews
        this.showChildView('navbar', new NavbarView({
            model: new AmpObject({active: "templates"})
        }));
        this.showChildView('template-list', new TemplateListView({
            collection: this.collection
        }));

        var selectedModel = this.collection.findWhere({isSelected: true});
        if (selectedModel) {
            // Hack to get rid of buggy rendering
            if (this.isDestroyed) {
                return;
            }

            this.showChildView('template-form', new TemplateFormView({
                model: selectedModel
            }));
        }

        // change form on model selection
        this.listenTo(this.collection, 'change:isSelected', function(changedModel) {
            // Hack to get rid of buggy rendering
            if (this.isDestroyed) {
                return;
            }
            
            if (!changedModel.isSelected) {
                return;
            }

            self.getRegion('template-form').show(new TemplateFormView({
                model: changedModel
            }));
        });
    }
});