// Booking Model - booking.js
var BaseModel = require('./base/model');

module.exports = BaseModel.extend({
    modelType: 'Booking',
    props: {
        id: {type: 'string'},
        familyName: {type: 'string'},
        givenName: {type: 'string'},
        displayName: {type: 'string'},
        createdAt: {type: 'iso-date'},
        itemToken: {type: 'string'},
        itemDisplayName: {type: 'string'}
    },
    session: {
        selectedItemToken: {type: 'string'},
        selectedItemDisplayName: {type: 'string'},
        state: {type: 'string', required: true, values: ['normal', 'selected', 'ejecting', 'moving'], default: 'normal'}
    },
    derived: {
        fullName: {
            deps: ['familyName', 'givenName'],
            fn: function() {
                return this.familyName+' '+this.givenName;
            }
        },
        isAssigned: {
            deps: ['itemToken'],
            fn: function() {
                return !!this.itemToken;
            }
        },
        isSelected: {
            deps: ['state'],
            fn: function() {
                return this.state === 'selected' || this.state === 'moving';
            }
        },
        isEjecting: {
            deps: ['state'],
            fn: function() {
                return this.state === 'ejecting';
            }
        },
        isMoving: {
            deps: ['state'],
            fn: function() {
                return this.state === 'moving';
            }
        },
        item: {
            deps: ['session.layout', 'itemToken'],
            cache: false,
            fn: function() {
                return this.session.layout.items.get(this.itemToken);
            }
        }
    },
    initialize: function() {
        this.listenTo(this, 'change:state', function(model, value) {
            if (value !== 'normal' && model.collection) {
                model.collection.resetStates(model);
            }
        });
    }
});

// references to other models
var _ = require('underscore');

_.extend(module.exports.prototype._children, {
    session: require('./session')
});