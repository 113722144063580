var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<li class=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"isSelected", env.opts.autoescape)?"active":""), env.opts.autoescape);
output += "\">\n    <a data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 1, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin:resourceId",{"resourceId": (lineno = 1, colno = 89, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"getId", env.opts.autoescape), "template[\"getId\"]", []))}])), env.opts.autoescape);
output += "\">\n        <div>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"name", env.opts.autoescape),"<i>Nouveau template</i>"), env.opts.autoescape);
output += "</div>\n        <div class=\"small\">ID : ";
output += runtime.suppressValue(env.getFilter("default").call(context, (lineno = 3, colno = 47, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"getId", env.opts.autoescape), "template[\"getId\"]", [])),"~"), env.opts.autoescape);
output += "</div>\n    </a>\n</li>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
