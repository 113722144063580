var Marionette = require('backbone.marionette');
var HeadingView = require('./session-sidebar/heading');
var IndexView = require('./session-sidebar/index');
var ListBookingsView = require('./session-sidebar/list-bookings');
var ListBikesView = require('./session-sidebar/list-bikes');
var ChangeLayoutView = require('./modal/change-layout');
var app = require('ampersand-app');
var $ = require('jquery');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/session-sidebar.nunj').render(data);
    },
    regions: {
        "sidebar-heading": ".region-sidebar-heading",
        "sidebar-tool": ".region-sidebar-tool"
    },
    onBeforeShow: function() {
        // initialize subviews
        this.showChildView('sidebar-heading', new HeadingView({
            model: this.model
        }));
        this.loadTool('index');
    },
    onAttach: function () {
        // Get heights
        var navbarH = $('nav.navbar').height();
        var sidebarHeadingH = $(this.el).find('.region-sidebar-heading').height();
        /*
        Remove Action button height from calculation as it's only visible on sidebar index which should probably not need overflow scroll
        Use sidebar padding-bottom instead
        var sidebarActionsH = $(this.el).find('.sidebar-actions').height();
        */

        // Calculate crop height
        var cropH = navbarH + sidebarHeadingH;

        // Set new height
        $(this.el).find('.region-sidebar-tool').height("calc(100vh - " + cropH + "px)");
    },
    loadTool: function(requestedTool) {
        switch (requestedTool) {
            case 'index':
                // show index tool
                this.showChildView('sidebar-tool', new IndexView({
                    model: this.model
                }));
                break;
            case 'list-bookings':
                // show list-bookings tool
                this.showChildView('sidebar-tool', new ListBookingsView({
                    model: this.model
                }));
                break;
            case 'edit-bookings':
                // show list-bookings tool (with editable: true)
                this.showChildView('sidebar-tool', new ListBookingsView({
                    model: this.model,
                    editable: true
                }));
                break;
            case 'list-bikes':
                // show list-bikes tool
                this.showChildView('sidebar-tool', new ListBikesView({
                    model: this.model
                }));
                break;
            case 'change-layout':
                // show change-layout modal
                app.rootView.openModal(new ChangeLayoutView({
                    model: this.model
                }));
                break;
            default:
                // no match, destroy current tool
                this.getRegion('sidebar-tool').empty();
        }
    },
    events: {
        "click [data-load]": function(event) {
            this.loadTool(this.$(event.currentTarget).data('load'));
        }
    }
});
