var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"title-info\">\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"itemToken", env.opts.autoescape)) {
output += "\n        Cliquez sur un élément libre pour déplacer ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"displayName", env.opts.autoescape), env.opts.autoescape);
output += "\n    ";
;
}
else {
output += "\n        Cliquez sur un élément libre pour placer ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"displayName", env.opts.autoescape), env.opts.autoescape);
output += "\n    ";
;
}
output += "\n</div>\n\n<div class=\"content-actions-fixed\">\n    <div class=\"btn-group btn-group-justified\">\n        <div class=\"btn-group\">\n            <button class=\"btn cta cta-delete\" data-hook=\"cancel-move\">\n                ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"itemToken", env.opts.autoescape)?"Annuler le déplacement":"Annuler le placement"), env.opts.autoescape);
output += "\n            </button>\n        </div>\n        <div class=\"btn-group\">\n            <button class=\"btn cta cta-normal\" data-hook=\"submit-move\">\n                ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"itemToken", env.opts.autoescape)?"Valider le déplacement":"Valider le placement"), env.opts.autoescape);
output += "\n            </button>\n        </div>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
