var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"modal-header\">\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><i class=\"glyphicon glyphicon-remove\"></i></button>\n    <h3 class=\"modal-title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape), env.opts.autoescape);
output += "</h3>\n    <h5 class=\"modal-title\">";
output += runtime.suppressValue(env.getFilter("toDateString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += " - ";
output += runtime.suppressValue(env.getFilter("toTimeString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</h5>\n    <h5 class=\"modal-title\">\n        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalAssignedBookings", env.opts.autoescape), env.opts.autoescape);
output += " places réservées sur ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"maximumBookableItems", env.opts.autoescape), env.opts.autoescape);
output += "\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalBookings", env.opts.autoescape) > runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalAssignedBookings", env.opts.autoescape)) {
output += "\n            (+";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalBookings", env.opts.autoescape) - runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"totalAssignedBookings", env.opts.autoescape), env.opts.autoescape);
output += ")\n        ";
;
}
output += "\n    </h5>\n</div>\n\n<div class=\"modal-body\">\n    <form class=\"form-horizontal\">\n        <div class=\"form-group\">\n            <label class=\"control-label col-sm-3\">Template</label>\n            <div class=\"col-sm-9\">\n                <select class=\"form-control\" name=\"template\">\n                    <option value=\"\"></option>\n                    ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "templates")),"models", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("template", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                        <option value=\"";
output += runtime.suppressValue((lineno = 21, colno = 54, runtime.callWrap(runtime.memberLookup((t_4),"getId", env.opts.autoescape), "template[\"getId\"]", [])), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue(((lineno = 21, colno = 88, runtime.callWrap(runtime.memberLookup((t_4),"getId", env.opts.autoescape), "template[\"getId\"]", [])) == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"template", env.opts.autoescape)?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name", env.opts.autoescape), env.opts.autoescape);
output += "</option>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </select>\n            </div>\n        </div>\n        <div class=\"row\">\n            <p class=\"help-block col-sm-offset-3 col-sm-9 text-left\">Le changement de template peut retirer certaines places attribuées.</p>\n        </div>\n    </form>\n</div>\n\n<div class=\"modal-footer\">\n    <div class=\"btn-group btn-group-justified\">\n        <div class=\"btn-group\">\n            <button type=\"button\" class=\"btn btn-danger\" data-dismiss=\"modal\">Annuler</button>\n        </div>\n        <div class=\"btn-group\">\n            <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit\">Oui, changer</button>\n        </div>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
