// Booking Collection - booking-collection.js
var BaseCollection = require('./base/collection');
var config = require('./../../config');

module.exports = BaseCollection.extend({
    indexes: ['itemToken', 'state'],
    comparator: 'displayName',
    url: function() {
        return config.api.base_url + '/' + config.api.client_token + '/bookings';
    },
    resetStates: function(ignoredModel) {
        this.each(function(model) {
            if (model !== ignoredModel) {
                model.state = 'normal';
            }
        });
    }
});

// references to child model
module.exports.prototype.model = require('./booking');
