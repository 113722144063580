var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "\n<div class=\"session-menu-mini\">\n    <ul>\n        <li><a class=\"btn btn-link\" data-load=\"list-bookings\">Voir la liste des réservations</a></li>\n        <li><a class=\"btn btn-link\" data-load=\"edit-bookings\">Modifier les placements & réservations</a></li>\n        <li><a class=\"btn btn-link\" data-load=\"change-layout\">Changer la carte</a></li>\n        <li><a class=\"btn btn-link\" data-load=\"list-bikes\">Activer / Désactiver un élément</a></li>\n        <li><a class=\"btn btn-link\" href=\"/";
output += runtime.suppressValue((lineno = 7, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin:resourceId/print",{"resourceId": (lineno = 7, colno = 108, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"getId", env.opts.autoescape), "session[\"getId\"]", []))}])), env.opts.autoescape);
output += "\" target=\"_blank\">Imprimer le plan de la session</a></li>\n    </ul>\n</div>\n\n";
output += "\n<div class=\"sidebar-actions session-actions\">\n    <a data-pushstate href=\"/";
output += runtime.suppressValue((lineno = 13, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin/:clientToken/sessions"])), env.opts.autoescape);
output += "\">\n        <button class=\"session-change\">Voir une autre session</button>\n    </a>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
