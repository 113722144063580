// Session Model - session.js
var BaseModel = require('./base/model');
var moment = require('moment');

module.exports = BaseModel.extend({
    modelType: 'Session',
    props: {
        startedAt: {type: 'iso-date'},
        name: {type: 'string'},
        createdAt: {type: 'iso-date'},
        maximumBookableItems: {type: 'number'},
        availableBookableItems: {type: 'number'},
        totalBookings: {type: 'number'},
        totalAssignedBookings: {type: 'number'},
        studio: {type: 'string'}
    },
    derived: {
        isCurrent: {
            deps: ['startedAt'],
            fn: function() {
                var start = moment().subtract(1, 'hour').toDate();
                var end = moment().toDate();

                return start < this.startedAt && this.startedAt < end;
            }
        },
        isComing: {
            deps: ['startedAt'],
            fn: function() {
                var start = moment().toDate();
                var end = moment().endOf('day').toDate();

                return start < this.startedAt && this.startedAt < end;
            }
        }
    }
});

// references to other models
var _ = require('underscore');

_.extend(module.exports.prototype._children, {
    layout: require('./layout')
});

_.extend(module.exports.prototype._collections, {
    bookings: require('./booking-collection')
});

_.extend(module.exports.prototype._children, {
    template: require('./template')
});