var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"btn-group btn-group-justified\">\n    <div class=\"btn-group\">\n        <button type=\"button\" class=\"btn btn-lg btn-info\">\n            <span>\n                <span class=\"validation-action-text\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "validationActionText"), env.opts.autoescape);
output += "</span>\n                <span class=\"bike-display-name\"></span>\n            </span>\n        </button>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
