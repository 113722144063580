var moment = require('moment');

module.exports = {
    toDateString: function(date, format) {
        var dateString = '';

        if (typeof format !== 'undefined' && format === 'short') {
            dateString = moment(date).format('L');
        } else {
            dateString = moment(date).format('LL');
        }

        return dateString;
    },
    toTimeString: function(date) {
        return moment(date).format('LT');
    }
};