var app = require('ampersand-app');
var Marionette = require('backbone.marionette');
var PageView = require('./views/page.js');
var Promise = require('any-promise');

module.exports = {
    showBooking: function (clientToken, bookingToken) {
        app.preExecute(clientToken);
        // use promises
        app.bookings.getOrFetch('/' + clientToken + '/bookings/' + bookingToken).then(function(booking) {
            booking.session.fetchOnce().then(function(session) {
                return Promise.all([
                    session.bookings.fetchOnce(),
                    session.template.fetchOnce()
                ]);
            }).then(function() {
                // model loading is complete, load the view
                app.rootView.showChildView("content", new PageView({
                    model: booking
                }));
            });
        }).catch(function() {
            app.rootView.showChildView("content", new Marionette.ItemView({
                template: function() {
                    return "<h1>404: Not Found</h1>";
                }
            }));
        });
    }
};