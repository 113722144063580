var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
if(runtime.contextOrFrameLookup(context, frame, "helpText")) {
output += "\n    <div class=\"help-text\">\n        <i class=\"fa fa-times remove-helptext\" aria-hidden=\"true\"></i>\n        <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "helpText"), env.opts.autoescape);
output += "</span>\n    </div>\n";
;
}
output += "\n<div class=\"vmap\"></div>\n<div class=\"vmap-controls\">\n    <div class=\"btn-group-vertical\">\n        <button class=\"vmap-focus btn btn-sm btn-info\"><i class=\"fa fa-crosshairs fa-lg\"></i></button>\n    </div>\n    <div class=\"btn-group-vertical\">\n        <button class=\"vmap-zoomin btn btn-sm btn-info\"><i class=\"fa fa-plus fa-lg\"></i></button>\n        <button class=\"vmap-zoomout btn btn-sm btn-info\"><i class=\"fa fa-minus fa-lg\"></i></button>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
