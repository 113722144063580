/* jshint browser : true */
var Marionette = require('backbone.marionette');
var tpl = require('../templates/modal/confirm-layout.nunj');
var config = require('./../../config');
var postMessageEnabled = config.api.post_message_enabled ? config.api.post_message_enabled : null;

module.exports = Marionette.ItemView.extend({
    events: {
        "click .btn-primary": "closePopup"
    },
    serializeData: function () {
      return {
            validationSuccessText: this.model.item.get('validationSuccessText')
        };
    },
    template: function (data) {
        return tpl.render(data);
    },
    closePopup: function() {
        this.trigger('close');

        if (parent && postMessageEnabled && document.referrer) {
            var urlSplitted = document.referrer.split( '/' );
            var origin = urlSplitted[0] + '//' + urlSplitted[2];
            parent.postMessage(this.model.selectedItemToken ?
              this.model.selectedItemToken : this.model.itemToken,
              origin);
        }
    }
});