// Booking Model - booking.js
var AmpModel = require('ampersand-unique-model');

module.exports = AmpModel.extend({
    modelType: 'Layout',
    getId: function() {
        return this.parent && this.parent.getId();
    },
    props: {
        width: {type: 'number'},
        height: {type: 'number'}
    }
});

// references to other models
var _ = require('underscore');

_.extend(module.exports.prototype._collections, {
    items: require('./item-collection')
});