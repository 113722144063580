var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"template-box\">\n    <div class=\"template-title\">\n        ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"name", env.opts.autoescape),"<i>Nouveau template</i>"), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"template-informations\">\n        <div class=\"table-row\">\n            <span class=\"table-cell\">ID : ";
output += runtime.suppressValue(env.getFilter("default").call(context, (lineno = 6, colno = 57, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "template")),"getId", env.opts.autoescape), "template[\"getId\"]", [])),"~"), env.opts.autoescape);
output += "</span>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
