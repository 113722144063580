var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<li class=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"isSelected", env.opts.autoescape)?"active":""), env.opts.autoescape);
output += "\">\n    <span class=\"id\">\n        --\n    </span>\n    <span class=\"name\">\n    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"givenName", env.opts.autoescape), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"familyName", env.opts.autoescape), env.opts.autoescape);
output += "\n    </span>\n    ";
if(runtime.contextOrFrameLookup(context, frame, "editable")) {
output += "\n        <span class=\"pull-right\">\n            <a class=\"action icon-link\" data-hook=\"action-assign\"><i class=\"icon icon-location-on\"></i></a>\n        </span>\n    ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "booking")),"isSelected", env.opts.autoescape)) {
output += "\n        <span class=\"pull-right\">\n            <a class=\"action\" data-load=\"edit-bookings\"><i class=\"fa fa-pencil\"></i><a/>\n        </span>\n    ";
;
}
;
}
output += "\n</li>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
