var Marionette = require('backbone.marionette');
var AmpObject = require('ampersand-object');
var NavbarView = require('./navbar');
var TemplateFormView = require('./template-form');
var TemplateSidebarView = require('./template-sidebar');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/page-template.nunj').render(data);
    },
    regions: {
        "navbar": ".region-navbar",
        "template-sidebar": ".region-template-sidebar",
        "template-form": ".region-template-form"
    },
    onBeforeShow: function() {
        // initialize subviews
        this.showChildView('navbar', new NavbarView({
            model: new AmpObject({active: "templates"})
        }));
        this.showChildView('template-sidebar', new TemplateSidebarView({
            model: this.model
        }));
        this.showChildView('template-form', new TemplateFormView({
            model: this.model
        }));
    }
});