var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div id=\"content\">Chargement en cours ...</div>\n\n<div class=\"modal fade\" tabindex=\"-1\">\n    <div id=\"modal\" class=\"modal-dialog\"></div>\n</div>\n\n<div class=\"legend\">\n    <span class=\"initial\"></span>\n    <span class=\"selected\"></span>\n    <div class=\"regions\">\n        <span class=\"bike-active\"></span>\n        <span class=\"bike-available\"></span>\n        <span class=\"bike-coach\"></span>\n        <span class=\"bike-unavailable\"></span>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
