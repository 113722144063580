// Session Collection - session-collection.js
var BaseCollection = require('./base/collection');
var config = require('./../../config');
var moment = require('moment');

module.exports = BaseCollection.extend({
    url: function() {
        return config.api.base_url + '/' + config.api.client_token + '/sessions';
    },
    defaultData: function() {
        var now = moment().startOf('day');

        return {
            'order[startedAt]': 'asc',
            'startedAt[after]': now.toISOString()
        };
    }
});

// references to child model
module.exports.prototype.model = require('./session');
