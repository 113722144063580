var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div>\n    <div class=\"sidebar-back\">\n        <span><a data-load=\"index\"><i class=\"fa fa-chevron-left\"></i></a></span>\n        <span>";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "editable")?"Modifier les réservations":"Liste des réservations"), env.opts.autoescape);
output += "</span>\n        <span><a href=\"/";
output += runtime.suppressValue((lineno = 4, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateUrl"), "generateUrl", ["admin:resourceId/print",{"resourceId": (lineno = 4, colno = 89, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"getId", env.opts.autoescape), "session[\"getId\"]", []))}])), env.opts.autoescape);
output += "\" target=\"_blank\"><i class=\"fa fa-print pull-right\"></i></a></span>\n    </div>\n\n    <div class=\"sidebar-scrollable\">\n        <div class=\"sidebar-menu\">\n            <div class=\"menu-group\">\n                Réservations placées <span data-hook=\"total-assigned\"></span>\n            </div>\n            <ul class=\"menu-list\" data-hook=\"list-assigned\"></ul>\n\n            <div class=\"menu-group\">\n                Réservations non placées <span data-hook=\"total-not-assigned\"></span>\n            </div>\n            <ul class=\"menu-list\" data-hook=\"list-not-assigned\"></ul>\n        </div>\n    </div>\n</div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
