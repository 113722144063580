var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
require( "./layout-sidebar.nunj" );
require( "./macro/marionette.nunj" );
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
env.getTemplate("./layout-sidebar.nunj", true, undefined, function(t_2,parentTemplate) {
if(t_2) { cb(t_2); return; }
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("./macro/marionette.nunj", false, undefined, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
t_3.getExported(function(t_5,t_3) {
if(t_5) { cb(t_5); return; }
if(t_3.hasOwnProperty("region")) {
var t_6 = t_3.region;
} else {
cb(new Error("cannot import 'region'")); return;
}
context.setVariable("region", t_6);
output += "\n\n";
output += "\n\n";
output += "\n";
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_sidebar(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "\n    <div class=\"row\">\n        ";
output += runtime.suppressValue((lineno = 5, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "region"), "region", ["session-sidebar"])), env.opts.autoescape);
output += "\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "\n    <div class=\"page-session\">\n        ";
output += runtime.suppressValue((lineno = 11, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "region"), "region", ["session-map"])), env.opts.autoescape);
output += "\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_sidebar: b_sidebar,
b_content: b_content,
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
