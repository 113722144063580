var Marionette = require("backbone.marionette");
var tpl = require("../templates/page.nunj");
var HeaderView = require('./header.js');
var MapView = require('./map.js');
var FooterView = require('./footer.js');

module.exports = Marionette.LayoutView.extend({
    className: "layout",
    template: function(data) {
        return tpl.render(data);
    },
    regions: {
        header: "header",
        main: "main",
        footer: "footer"
    },
    onShow: function() {
        this.showChildView('header', new HeaderView({
            model: this.model
        }));
        this.showChildView('main', new MapView({
            model: this.model
        }));
        this.showChildView('footer', new FooterView({
            model: this.model
        }));
    }
});