var AmpView = require('ampersand-view');
var VirtualCollection = require('./../../common/adapter/backbone-virtual-collection');
var moment = require('moment');
var _ = require('underscore');
var $ = require('jquery');
require('form-serializer');

var ItemView = AmpView.extend({
    template: function() {
        return require('../templates/session-list-item.nunj').render({
            session: this.model
        });
    },
    initialize: function() {
        this.listenTo(this.model, 'change', this.render);
    },
    render: function() {
        this.renderWithTemplate();
    }
});

module.exports = AmpView.extend({
    childView: ItemView,
    template: function() {
        return require('../templates/session-list.nunj').render();
    },
    events: {
        'change .filter-group': 'onFilterChange',
        'submit .filter-group': 'onFilterChange'
    },
    initialize: function() {
        // build virtual collections
        this.currentSessions = new VirtualCollection(this.collection, {
            filter: function(model) {
                return model.isCurrent;
            }
        });
        this.comingSessions = new VirtualCollection(this.collection, {
            filter: function(model) {
                return model.isComing;
            }
        });
        this.allSessions = new this.collection.constructor(this.collection.models);
    },
    render: function() {
        this.renderWithTemplate();

        // render subcollections
        this.renderCollection(this.currentSessions, this.childView, this.queryByHook('session-current'));
        this.renderCollection(this.comingSessions, this.childView, this.queryByHook('session-coming'));
        this.renderCollection(this.allSessions, this.childView, this.queryByHook('session-all'));
    },
    onFilterChange: function(event) {
        event.preventDefault();

        var options = {data: _.result(this.allSessions, 'defaultData', {})};
        var filterValues = $(this.query('.filter-group')).serializeObject();

        // parse filter values
        if (filterValues.startedAt) {
            options.data['startedAt[after]'] = moment(filterValues.startedAt).startOf('day').toISOString();
            options.data['startedAt[before]'] = moment(filterValues.startedAt).endOf('day').toISOString();
        }

        if (filterValues.name) {
            options.data.name = filterValues.name;
        }

        // send update request
        this.allSessions.fetch(options);
    }
});