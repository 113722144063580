var app = require('ampersand-app');
var Marionette = require('backbone.marionette');
var Promise = require('any-promise');

module.exports = {
    listSessions: function(clientToken) {
        app.preExecute(clientToken);

        app.sessions.fetchOnce().then(function(sessionCollection) {
            var PageView = require('./views/page-session-list');

            app.rootView.showChildView("content", new PageView({
                collection: sessionCollection
            }));
        });
    },

    showSession: function(clientToken, sessionId) {
        app.preExecute(clientToken);

        app.sessions.getOrFetch('/' + clientToken + '/sessions/' + sessionId).then(function(session) {
            session.bookings.fetch();
            app.templates.fetchOnce();

            var PageView = require('./views/page-session');

            app.rootView.showChildView("content", new PageView({
                model: session
            }));
        }).catch(function() {
            app.rootView.showChildView("content", new Marionette.ItemView({
                template: function() {
                    return "<h1>404: Not Found</h1>";
                }
            }));
        });
    },

    printSession: function(clientToken, sessionId) {
        app.preExecute(clientToken);

        app.sessions.getOrFetch('/' + clientToken + '/sessions/' + sessionId).then(function(session) {
            return Promise.all([
                session.bookings.fetch()
            ]).then(function() {
                // lazy wiring
                session.bookings.forEach(function(booking) {
                    booking.session = session;
                });

                // model loading is complete, load the view
                var PageView = require('./views/page-session-print');

                app.rootView.showChildView("content", new PageView({
                    model: session
                }));
            });
        });
    },

    listTemplates: function(clientToken) {
        app.preExecute(clientToken);

        app.templates.fetchOnce().then(function(templateCollection) {
            var PageView = require('./views/page-template-list');

            app.rootView.showChildView("content", new PageView({
                collection: templateCollection
            }));
        });
    },

    showTemplate: function(clientToken, templateId) {
        app.preExecute(clientToken);

        app.templates.getOrFetch('/' + clientToken + '/templates/' + templateId).then(function(template) {
            var PageView = require('./views/page-template');

            app.rootView.showChildView("content", new PageView({
                model: template
            }));
        }).catch(function() {
            app.rootView.showChildView("content", new Marionette.ItemView({
                template: function() {
                    return "<h1>404: Not Found</h1>";
                }
            }));
        });
    }
};